#quick-panel {

    .md-subheader .md-subheader-inner {
        display: block;
        padding: 6px;
    }
    .today-tab {


    }
    ::-webkit-scrollbar {
        width: 8px;
        margin-top: 10px;
        
    }
        
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey; 
        border-radius: 2px;

    }
        
    ::-webkit-scrollbar-thumb {
        background:#039be5; 
        border-radius: 2px;
        
    }
        
    ::-webkit-scrollbar-thumb:hover {
        background:rgb(11, 70, 126); 
    }
}