#mail {

    .center {

        .content-card {

            &.outlook {

                .content-wrapper {

                    .thread-detail {
                        background: #FFFFFF;

                        .no-thread-selected {

                            md-icon {
                                margin: -56px 0 12px 0;
                            }

                            span {
                                font-size: 24px;
                                font-weight: 300;
                            }
                        }

                        .thread-header {
                            padding-bottom: 24px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                            .actions {
                                min-width: 88px;

                                .md-icon-button {
                                    padding: 0;
                                    width: 32px;
                                    height: 32px;
                                }
                            }

                            .subject {
                                font-size: 17px;
                                font-weight: 500;
                            }

                            .label {
                                display: inline-block;
                                font-size: 11px;
                                padding: 0 5px;
                                margin: 8px 6px 0 0;
                            }
                        }

                        .thread-content {
                            padding: 24px 0;

                            .to {
                                color: rgba(0, 0, 0, 0.54);

                                .to-text {
                                    margin-right: 4px;
                                    text-transform: lowercase;
                                }
                            }

                            .info {
                                padding-bottom: 16px;

                                .avatar {
                                    margin-right: 16px;
                                }

                                .name {
                                    margin-right: 8px;
                                    font-weight: 500;
                                }

                                .toggle-details {
                                    padding-top: 16px;
                                    cursor: pointer;
                                    font-weight: 500;
                                }

                                .details {
                                    padding-top: 8px;

                                    .title {
                                        font-weight: 500;
                                        margin-right: 6px;
                                    }

                                    .detail {
                                        color: rgba(0, 0, 0, 0.54);
                                    }
                                }

                            }
                        }

                        .thread-attachments {
                            padding: 24px 0;
                            border-top: 1px solid rgba(0, 0, 0, 0.12);

                            .title {
                                margin-bottom: 16px;
                                font-weight: 500;
                            }

                            .attachment {

                                .preview {
                                    width: 100px;
                                    margin: 0 16px 8px 0;
                                }

                                .link {
                                    margin-bottom: 2px;
                                }

                                .size {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}