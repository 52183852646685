#order {
    .center {

        .content-card {

            &.outlook {

                .content-wrapper {

                    .thread-detail {
                        background: transparent;
                        position: relative;

                        .no-thread-selected {

                            md-icon {
                                margin: -56px 0 12px 0;
                            }

                            span {
                                font-size: 24px;
                                font-weight: 300;
                            }
                        }

                        .thread-header {
                            padding-bottom: 24px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                            .actions {
                                min-width: 88px;

                                .md-icon-button {
                                    padding: 0;
                                    width: 32px;
                                    height: 32px;
                                }
                            }

                            .subject {
                                font-size: 17px;
                                font-weight: 500;
                            }

                            .label {
                                display: inline-block;
                                font-size: 11px;
                                padding: 0 5px;
                                margin: 8px 6px 0 0;
                            }
                        }

                        .thread-content {
                            padding: 24px 0;

                            .to {
                                color: rgba(0, 0, 0, 0.54);

                                .to-text {
                                    margin-right: 4px;
                                    text-transform: lowercase;
                                }
                            }

                            .info {
                                padding-bottom: 16px;

                                .avatar {
                                    margin-right: 16px;
                                }

                                .name {
                                    margin-right: 8px;
                                    font-weight: 500;
                                }

                                .toggle-details {
                                    padding-top: 16px;
                                    cursor: pointer;
                                    font-weight: 500;
                                }

                                .details {
                                    padding-top: 8px;

                                    .title {
                                        font-weight: 500;
                                        margin-right: 6px;
                                    }

                                    .detail {
                                        color: rgba(0, 0, 0, 0.54);
                                    }
                                }

                            }
                        }

                        .thread-attachments {
                            padding: 24px 0;
                            border-top: 1px solid rgba(0, 0, 0, 0.12);

                            .title {
                                margin-bottom: 16px;
                                font-weight: 500;
                            }

                            .attachment {

                                .preview {
                                    width: 100px;
                                    margin: 0 16px 8px 0;
                                }

                                .link {
                                    margin-bottom: 2px;
                                }

                                .size {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .order-content {
        padding: 24px 0;
        background-color: transparent;

        .card-breadcrumb {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 12px;
        }

        .tab-body {
            background: transparent;
        }

        .card-subscribe {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.6);
        }

        .card-name {
            width: 100%;
            font-size: 24px;

            .editable-input {
                font-size: 24px;
            }
        }

        .due-date {

            .moment-picker-input {
                background: material-color('grey', '200');
                padding: 8px 10px;

                &:hover {
                    background: material-color('grey', '300');
                }

                i {
                    margin-right: 8px;
                }
            }

            .moment-picker-container {
                margin-top: 8px;
                right: 0;

                &:before, &:after {
                    right: 15px;
                    left: auto;
                }
            }

            .remove-due-date {
                background: material-color('grey', '200');
                padding: 9px;
                cursor: pointer;

                &:hover {
                    background: material-color('grey', '300');
                }
            }
        }

        .description {
            padding-bottom: 16px;
        }

        .sections {

            .section {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                margin-bottom: 32px;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;

                    .section-content {
                        padding-bottom: 0;
                    }
                }

                .section-header {
                    font-size: 16px;

                    i {
                        margin-right: 8px;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .section-title {
                        font-weight: 500;
                    }
                }

                .section-content {
                    padding: 24px 0 32px 0;
                }

                .labels {

                    .section-content {
                        padding: 8px 0 32px 0;
                    }

                    .label-chips {

                        .md-chips {
                            box-shadow: none;
                            padding: 0;

                            md-chip {
                                background: transparent;
                                padding-right: 0 !important;
                                padding-left: 0 !important;

                                .label-chip {
                                    padding: 0 32px 0 12px;
                                    border-radius: 16px;
                                    display: block;
                                    height: 32px;
                                    line-height: 32px;
                                    font-size: 14px;
                                }

                                .md-chip-remove-container {
                                    right: 12px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }

                .members {

                    .section-content {
                        padding: 8px 0 32px 0;
                    }

                    .member-chips {

                        .md-chips {
                            box-shadow: none;
                            padding: 0;

                            md-chip {
                                padding-left: 0;

                                .member-chip {

                                    .member-chip-avatar {
                                        width: 32px;
                                        border-radius: 50%;
                                    }
                                }

                                .md-chip-remove-container {
                                    right: 6px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }

                .attachments {

                    .attachment {
                        margin-bottom: 16px;

                        .attachment-preview {
                            background-color: #EEF0F2;
                            width: 160px;
                            height: 128px;
                            background-size: contain;
                            background-position: 50% 50%;
                            background-repeat: no-repeat;
                            margin-right: 24px;
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.6);
                        }

                        .attachment-content {

                            .attachment-url,
                            .attachment-name {
                                font-weight: 500;
                                font-size: 16px;
                            }

                            .attachment-is-cover {
                                margin-left: 6px;
                            }

                            .attachment-time {
                                color: rgba(0, 0, 0, 0.6);
                            }

                            .attachment-actions-button {
                                background-color: white;
                                text-transform: capitalize;
                                margin: 12px 0 0 0;
                                padding-left: 12px;

                                i {
                                    margin-left: 8px;
                                    color: rgba(0, 0, 0, 0.6);
                                }
                            }
                        }
                    }

                    .add-attachment-button {
                        margin: 0;

                        i {
                            color: rgba(0, 0, 0, 0.6);
                            margin-right: 8px;
                        }

                        span {
                            font-weight: 500;
                            text-transform: capitalize;
                        }
                    }
                }

                .checklist {

                    .checklist-progress {
                        margin-bottom: 16px;

                        .checklist-progress-value {
                            margin-right: 12px;
                            font-weight: 500;
                            white-space: nowrap;
                            font-size: 14px;
                        }

                        .checklist-progressbar {
                        }
                    }

                    .editable-wrap {
                        flex: 1
                    }

                    .check-items {

                        .check-item {

                            md-checkbox {
                                margin-bottom: 0;

                                .md-label {
                                    font-size: 14px;
                                }

                                &.md-checked {

                                    .md-label {
                                        text-decoration: line-through;
                                        color: rgba(0, 0, 0, 0.6);
                                    }
                                }
                            }
                        }
                    }

                    .new-check-item-form {
                        padding-top: 16px;

                        md-input-container {
                            margin: 0;
                        }

                        .md-button {
                            margin: 0 0 0 16px;
                        }
                    }
                }

                .comments {

                    .comment {
                        margin-bottom: 16px;

                        .comment-member-avatar {
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            margin-right: 16px;
                        }

                        .comment-member-name {
                            font-size: 14px;
                            font-weight: 500;
                        }

                        .comment-time {
                            font-size: 12px;
                        }

                        .comment-bubble {
                            position: relative;
                            padding: 8px;
                            background-color: white;
                            border: 1px solid rgb(220, 223, 225);
                            font-size: 14px;
                            margin: 4px 0;

                            &:after,
                            &:before {
                                content: ' ';
                                position: absolute;
                                width: 0;
                                height: 0;
                            }

                            &:after {
                                left: -7px;
                                right: auto;
                                top: 0px;
                                bottom: auto;
                                border: 11px solid;
                                border-color: white transparent transparent transparent;
                            }

                            &:before {
                                left: -9px;
                                right: auto;
                                top: -1px;
                                bottom: auto;
                                border: 8px solid;
                                border-color: rgb(220, 223, 225) transparent transparent transparent;
                            }
                        }

                        &.new-comment {

                            md-input-container {
                                margin: 0;
                            }
                        }
                    }
                }

                .activities {

                    .activity {
                        margin-bottom: 12px;

                        .activity-member-avatar {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            margin-right: 16px;
                        }

                        .activity-member-name {
                            font-size: 14px;
                            font-weight: 500;
                            margin-right: 8px;
                        }

                        .activity-message {
                            font-size: 14px;
                            margin-right: 8px;
                        }

                        .activity-time {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}