.autocomplete-custom-template li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: normal;
  }
  .autocomplete-custom-template li:last-child {
    border-bottom-width: 0;
  }
  .autocomplete-custom-template .item-title,
  .autocomplete-custom-template .item-metadata {
    display: block;
    line-height: 2;
  }
  .autocomplete-custom-template .item-title md-icon {
    height: 18px;
    width: 18px;
  }