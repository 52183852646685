#order {

    // Center
    .center {

        .header {
            padding: 24px 0;

            .sidenav-toggle {
                margin: 0;
                width: 56px;
                height: 56px;
                background: #FFFFFF;
                border-radius: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }

            .search {
                height: 56px;
                line-height: 56px;
                padding: 18px;
                background: #FFFFFF;

                .icon {
                    margin: 0;
                }

                input {
                    padding-left: 16px;
                    height: 56px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }

    .editable-click {
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        border-bottom: none;
    }

    .editable-wrap {
        display: block;
        position: relative;

        .editable-controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .editable-input {
                width: inherit;
                background-color: white;
                padding: 8px;
                border: 1px solid rgba(0, 0, 0, 0.12);
            }

            .editable-buttons {
                display: inherit;

                .md-button {
                    margin: 0;

                    &:first-of-type {
                        padding-right: 0;
                    }

                    .icon-cancel {
                        color: rgba(0, 0, 0, 0.32);
                    }
                }
            }
        }
    }
    .board-selector-backdrop {
        z-index: 47;
    }

        .content-card {

        }
    }
}