#order {
    .jobs-table {
        
        font-size: 15px;
        zoom: 90%;

        .title {
            font-size: 17px;
        }

        .detail {
            margin-top: 8px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            max-width: 450px;
        }
        .status {
            font-size: 12px;
            white-space: nowrap;
            padding: 2px 5px;
            border-radius: 4px;
            color: white;
        }
    }
}