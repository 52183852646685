#layout-content-with-toolbar {
    .avatar {
        width: 30px;
        min-width: 30px;
        height: 30px;
    }
    #content {
        top:  45px;
    }
    #toolbar {
        padding: 0 0 0 10px;
        height: 45px;


        .logo {
            margin-right: 5px;

            .logo-image {
                display: block;
                background: material-color('light-blue', '600');
                width: 32px;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
                border-radius: 2px;
            }

            .logo-text {
                color: rgba(0, 0, 0, 0.87);
                margin-left: 16px;
                font-size: 16px;
            }
        }
    }
}
