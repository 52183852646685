$quickPanelWidth: 100%;
$quickPanelMobileWidth: 80%;


.editable-wrap .editable-controls > input, .editable-wrap .editable-controls > select, .editable-wrap .editable-controls > textarea {
    font-size: 14px;

}

.editable-buttons button {
    margin-left: 5px;
    background-color: #336986;
    font-size: 14px;
    color: white;
    padding: 2px;
    border-radius: 2px;
}

.editable-wrap {
    //display: inline-block;
    //white-space: pre;
    //margin-left: 0;
    float: right;
    margin-right: 0px;
    padding: 0px;
    
}


[uib-typeahead-popup].dropdown-menu {
    //display: absolute;
    position: absolute;
    font-size: 12px;
    background-color: white;
    color: black;
    padding: 12px;
    border: #336986 1px solid;
    z-index: 2000;

}


.md-datepicker-input-container {
    //width: 1px;
    //visibility: hidden;
}


#quick-panel {
    width: $quickPanelWidth;
    min-width: $quickPanelWidth;
    max-width: $quickPanelWidth;
    z-index: 99;
    .md-datepicker-input-container {
        //width: 1px;
        //visibility: hidden;
    }
    md-content {
        height: 100%;

        md-tabs {
            height: 100%;

            md-tabs-wrapper {
                background-color: rgba(0, 0, 0, 0.03);
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);

                .md-tab {
                    max-width: none !important;
                }
            }

            md-tabs-content-wrapper {

                md-tab-content {
                    height: 100%;
                    overflow: hidden;

                    > div {
                        overflow: hidden;
                        height: 100%;
                    }

                    md-list {

                        .md-subheader {

                        }

                        md-list-item {
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #quick-panel {
        width: $quickPanelMobileWidth;
        min-width: $quickPanelMobileWidth;
        max-width: $quickPanelMobileWidth;
    }
}