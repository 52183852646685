#mail {

    // Center
    .center {

        .header {
            padding: 14px 0;

            .sidenav-toggle {
                margin: 0;
                width: 56px;
                height: 56px;
                background: #FFFFFF;
                border-radius: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }

            .search {
                height: 26px;
                line-height: 26px;
                padding: 6px;
                background: #FFFFFF;

                .icon {
                    margin: 0;
                }

                input {
                    padding-left: 16px;
                    height: 56px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }

        .content-card {

        }
    }
}