/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/datatables-responsive/css/responsive.dataTables.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/apps/jobs/views/classic/detail/detail.scss";
@import "main/apps/jobs/views/classic/list/list.scss";
@import "main/apps/jobs/views/outlook/detail/detail.scss";
@import "main/apps/jobs/views/outlook/list/list.scss";
@import "main/apps/order/views/classic/detail/detail.scss";
@import "main/apps/order/views/outlook/detail/detail.scss";
@import "main/apps/order/views/classic/list/list.scss";
@import "main/apps/order/views/outlook/workpanel/actionassign.scss";
@import "main/apps/order/views/outlook/workpanel/jobadd.scss";
@import "main/apps/order/views/outlook/workpanel/workpanel.scss";
@import "main/apps/order/views/outlook/list/actionList.scss";
@import "main/apps/order/views/outlook/list/jobList.scss";
@import "main/apps/order/views/outlook/list/list.scss";
@import "toolbar/toolbar.scss";
@import "quick-panel/quick-panel.scss";
@import "main/components/components.scss";
@import "main/apps/destinations/destination.scss";
@import "main/apps/destinations/destinationAdd.scss";
@import "main/apps/destinations/destinations.scss";
@import "main/apps/jobs/jobs.scss";
@import "main/apps/warehouses/warehouse.scss";
@import "main/apps/warehouses/warehouseAdd.scss";
@import "main/apps/warehouses/warehouses.scss";
@import "main/apps/order/caldrivers.scss";
@import "main/apps/order/calendar.scss";
@import "main/apps/order/order.scss";
@import "main/components/charts/charts.scss";
@import "main/components/maps/maps.scss";
@import "main/components/price-tables/price-tables.scss";
@import "main/components/material-docs/material-doc-template.scss";
@import "main/components/widgets/widgets.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "toolbar/dialogs/truck/truck-detail.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "main/apps/order/views/addorder.scss";
@import "main/pages/auth/login/login.scss";
@import "main/apps/jobs/sidenavs/main/main-sidenav.scss";
@import "main/apps/jobs/views/classic/classic-view.scss";
@import "main/apps/jobs/dialogs/compose/compose-dialog.scss";
@import "main/apps/jobs/views/outlook/outlook-view.scss";
@import "main/apps/order/dialogs/actiondrop/actiondrop-dialog.scss";
@import "main/apps/order/dialogs/client/client-dialog.scss";
@import "main/apps/order/dialogs/destination/destination-dialog.scss";
@import "main/apps/order/dialogs/driver/driver-dialog.scss";
@import "main/apps/order/dialogs/editorderaction/editorderaction-dialog.scss";
@import "main/apps/order/dialogs/event/event-dialog.scss";
@import "main/apps/order/dialogs/trailer/trailer-dialog.scss";
@import "main/apps/order/dialogs/supplier/supplierform-dialog.scss";
@import "main/apps/order/dialogs/truck/truckadd-dialog.scss";
@import "main/apps/order/dialogs/orderaction/orderaction-dialog.scss";
@import "main/apps/order/sidenavs/action/action-sidenav.scss";
@import "main/apps/order/dialogs/warehouse/warehouse-dialog.scss";
@import "main/apps/order/sidenavs/calendar-tasks/calendar-tasks-sidenav.scss";
@import "main/apps/order/sidenavs/job/job-sidenav.scss";
@import "main/apps/order/sidenavs/main/main-sidenav.scss";
@import "main/apps/order/sidenavs/order/order-sidenav.scss";
@import "main/apps/order/views/classic/classic-view.scss";
@import "main/apps/order/views/outlook/outlook-view.scss";
// endinjector