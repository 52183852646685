#order {

    .center {

        .content-card {

            &.outlook {

                .toolbar {
                    position: relative;
                    padding: 8px 16px;

                    .select-menu {

                        .md-icon-button {
                            padding-left: 0;
                            margin: 0;
                        }
                    }

                    .page-info {
                        font-weight: 500;
                        margin: 0 8px;
                        white-space: nowrap;
                    }

                    .close-thread {
                        margin: 0 0 0 -8px;
                    }
                }

                .content-wrapper {
                    position: relative;
                    height: 100%;
                }
            }
        }
    }
}

.thread-labels-toggle-list {

    md-list-item {

        md-icon {
            margin: 0 !important;
        }
    }
}