#order {

    // Main sidenav
    .main-sidenav {
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        z-index: 51;
        box-shadow: $whiteframe-shadow-8dp;

        .header {
            height: 150px;
            min-height: 150px;
            max-height: 150px;
            padding: 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            .logo {

                .logo-icon {
                    margin: 0 16px 0 0;
                }

                .logo-text {
                    font-size: 24px;
                }
            }

            .account {

                .account-selection {
                    margin: 0;
                }
            }
        }

        .content {
            padding: 16px 0;
        }
    }
}